const lang = {
  login: {
    title: 'Admin Login',
  },
  logout: {
    action: 'Log Out',
  },
  showMore: 'Show more',
  showLess: 'Show less',
  admin: {
    all: {
      viewButton: 'Admins',
      title: 'All Admins',
    },
    add: {
      action: 'Add an admin',
    },
  },
  tenant: {
    actions: { add: 'Add client', update: 'Edit client' },
    all: {
      headers: {
        projectID: 'Project ID',
        country: 'Country',
        active: 'Active',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        clientName: 'Client Name',
        actions: {
          label: 'Actions',
          viewAllUsers: 'View all Users',
          edit: 'Edit',
          editFeatureFlags: 'Edit Feature Flags',
        },
      },
      viewButton: 'View all Clients',
      title: 'All Clients',
    },
  },
  engagement: {
    single: {
      title: 'This is the Engagement Page',
      add: {
        action: 'Add an engagement',
      },
      status: {
        update: 'Update Status',
      },
      survey: {
        refresh: {
          success: 'The survey has been successfully refreshed.',
          failure: 'The survey refresh has failed. Please try again.',
          inProgress:
            'A refresh for this survey is already in progress, please wait for it to complete.',
          button: 'Refresh Survey',
        },
      },
      delete: 'Delete engagement',
      preview: 'Preview Engagement',
      actions: {
        editCommentary: 'Edit Commentary',
        editDemo: 'Edit Demographics',
        uploadFile: 'Upload file',
      },
      hideDemographics: 'Hide Demographics for this engagement',
      showDemographics: 'Display demographics for this engagement',
      details: 'Details',
      files: {
        header: 'Files',
        description:
          'All uploaded files will display on the engagement page and will display as named',
      },
      comparableEngagement: {
        title: 'AI generated list of comparable engagements',
        themes: 'Identified themes',
        current: {
          themes: 'Identified themes for current engagement:',
        },
      },
      comparisonAnalysis: {
        linkTitle: 'Comparative analysis',
        regenerateComparisons: 'Regenerate comparisons',
        generateComparisons: 'Generate comparisons',
        successfulComparisons:
          'Comparison generation initiated, can take 10-20 minutes to complete.',
        globalReport: {
          title: 'Global report',
          notFound:
            'Create a comparative analysis by clicking on the "Regenerate" icon.',
          delete: 'Delete',
          successfulDelete: 'Comparative analysis deleted successfully',
          regenerateReport: 'Regenerate',
        },
        questions: {
          title: 'Comparable questions',
          themes: 'Themes',
          type: 'Question type',
          description: 'Listed below are comparable questions.',
          notFound: 'No comparable questions found',
          annotations: 'Annotations',
          notes: 'AI notes',
          comparisonReport: 'Comparison summary',
          delete: 'Delete',
          successfulDelete: 'Question comparison deleted successfully',
          regenerateReport: 'Regenerate',
          successfulReport: 'Report regenerated successfully',
          deleteReport: 'Delete summary',
          successfulDeleteReport: 'Questions summary deleted successfully',
        },
      },
    },
    all: {
      headers: {
        clientName: 'Client Name',
        internalName: 'Internal name',
        fullName: 'Full name',
        status: 'Status',
        expectedParticipants: 'Expected number of participants',
        creationDate: 'Creation date',
        startDate: 'Start date',
        endDate: 'End date',
        completedDate: 'Completed date',
        surveySparrowID: 'Survey Sparrow ID',
        actions: 'Actions',
      },
      title: 'All engagements',
      viewButton: 'View all engagements',
    },
  },
  responses: {
    all: {
      title: 'All responses',
      uploadDemographics: 'Upload Demographics',
    },
  },
  user: {
    single: {
      title: 'This is the User Page',
      add: {
        action: 'Add User',
      },
      update: {
        action: 'Edit User',
      },
    },
    all: {
      viewButton: 'View all users',
      title: 'All users',
    },
  },
  input: {
    select: 'Select option',
  },
  errors: {
    default: 'An unexpected error occurred. Please try again.',
    notFound: {
      title: 'Page not Found',
    },
  },
  components: {
    fileUpload: {
      defaultLabel: 'Upload',
    },
  },
};

export default lang;
